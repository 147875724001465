/* eslint-disable react/jsx-no-target-blank */
import React, { useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ReactComponent as Logo } from "../../../assets/Logo.svg";
import "./styles.css";
import { Link, useLocation } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeNavbar() {
  const diclosureButton = useRef<any>();

  const location = useLocation();

  const navigation = [
    // { name: "Nosotros", href: "me", current: true },
    { name: "Tips", href: "tip", current: false },
    // { name: "Contacto", href: "podcast", current: false },
  ];

  return (
    <nav className="absolute top-0 right-0 left-0 flex justify-center z-50 border-0">
      <Disclosure
        as="div"
        className="absolute tc-nav bg-white lg:bg-transparent flex items-center mt-0 lg:mt-0 border-0"
      >
        {({ open }) => (
          <div className="w-full">
            <div className={`relative w-full px-4 lg:px-10`}>
              <div className="grid grid-rows-1 grid-cols-2 lg:grid-cols-2 w-full">
                <div className="flex" style={{ width: "100%" }}>
                  <Link to="/home" className="flex-shrink-0 flex items-center">
                    <Logo className="block h-8 w-auto" />
                  </Link>

                  <div
                    className="hidden ml-0 lg:ml-10 lg:flex md:space-x-8"
                    style={{
                      justifyContent: "start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="flex space-x-1 items-center ">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            // item.current ? "currentColor" : "",
                            `px-3 text-sm font-medium max-h-9 whitespace-nowrap 
                            ${
                              item.href === location.pathname.split("/")[2]
                                ? "text-fiwi-purple-200"
                                : "text-gray-500"
                            }`
                          )}
                        >
                          {item.name}
                        </Link>
                      ))}
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href="https://api.whatsapp.com/send?phone=51908941884&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Wifi!"
                        className={classNames(
                          // item.current ? "currentColor" : "",
                          `px-3 text-sm font-medium max-h-9 whitespace-nowrap text-gray-500
                            `
                        )}
                      >
                        Contacto
                      </a>
                    </div>
                  </div>
                </div>

                <div className="md:mr-14 xl:mr-0 ml-16 flex justify-end lg:justify-end">
                  <div className="hidden lg:flex space-x-3 items-center">
                    <div className="flex-shrink-0 hidden lg:flex">
                      <a
                        rel="noreferrer"
                        target={"_blank"}
                        href="https://api.whatsapp.com/send?phone=51908941884&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Wifi!"
                        className={`mr-10 flex items-center font-bold py-2 
                        ${
                          location.pathname.split("/").length === 2
                            ? "text-white"
                            : "text-fiwi-purple-200"
                        }
                         px-4 rounded-md text-sm border-2 
                         ${
                           location.pathname.split("/").length === 2
                             ? "border-white"
                             : "border-fiwi-purple-200"
                         }`}
                      >
                        <span>Reservar ahora</span>
                      </a>
                    </div>
                  </div>

                  <div className="flex justify-end lg:hidden">
                    <Disclosure.Button
                      ref={diclosureButton}
                      className="inline-flex items-center justify-center p-2 rounded-sm text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-tc-blue-300"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            {/* MOBILE */}
            <Disclosure.Panel className="lg:hidden absolute bg-white w-full z-10 rounded-b-md">
              <div className="pt-2 pb-3 space-y-1 border-t border-gray-200">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => {
                      diclosureButton.current?.click();
                    }}
                    className="border-transparent text-sm text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 font-medium sm:pl-5 sm:pr-6"
                  >
                    {item.name}
                  </Link>
                ))}
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://api.whatsapp.com/send?phone=51908941884&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Wifi!"
                  className="border-transparent text-sm text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 font-medium sm:pl-5 sm:pr-6"
                >
                  Contacto
                </a>
                <div className="border-t border-gray-200" />
                <div className="flex justify-center">
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    className="border-transparent text-gray-500 block pl-3 pr-4 py-2 border-l-4 text-base font-semibold sm:pl-5 sm:pr-6"
                    href="https://api.whatsapp.com/send?phone=51908941884&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Wifi!"
                  >
                    Citas
                  </a>
                </div>
              </div>
            </Disclosure.Panel>
            {open && (
              <div
                className="absolute lg:hidden bg-gray-500 opacity-50 h-screen w-screen right-0"
                onClick={() => {
                  diclosureButton.current?.click();
                }}
              />
            )}
          </div>
        )}
      </Disclosure>
    </nav>
  );
}
